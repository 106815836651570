import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { processProposalDescriptionText } from '../../utils/processProposalDescriptionText';
import { Proposal } from '../../wrappers/nounsDao';
import remarkBreaks from 'remark-breaks';
import { buildEtherscanAddressLink, buildEtherscanTxLink } from '../../utils/etherscan';
import { utils } from 'ethers';
import classes from './ProposalContent.module.css';
import { Trans } from '@lingui/macro';
import renaToken from '../../assets/icons/rena.png';
interface ProposalContentProps {
  proposal?: Proposal;
}

const linkIfAddress = (content: string) => {
  if (utils.isAddress(content)) {
    return (
      <a href={buildEtherscanAddressLink(content)} target="_blank" rel="noreferrer">
        {content}
      </a>
    );
  }
  return <span>{content}</span>;
};

const transactionLink = (content: string) => {
  return (
    <a href={buildEtherscanTxLink(content)} target="_blank" rel="noreferrer">
      {content.substring(0, 7)}
    </a>
  );
};

const ProposalContent: React.FC<ProposalContentProps> = props => {
  const { proposal } = props;
  const listInfo = [
    {
      left: 'Author',
      right: {
        img: renaToken,
        name: 'JRN Breeder',
      },
    },
    {
      left: 'Token',
      right: {
        img: renaToken,
        name: 'RENA',
      },
    },
    {
      left: 'Start Date',
      right: {
        name: 'May 13, 2022 11:00 PM',
      },
    },
    {
      left: 'End Date',
      right: {
        name: 'May 13, 2022 11:00 PM',
      },
    },
    {
      left: 'Block Number',
      right: {
        name: '14762737',
      },
    },
  ];
  return (
    <>
      <div className={classes.container}>
        <div className={classes.content}>
          <Row>
            <Col className={classes.section}>
              <h4>
                <Trans>Description</Trans>
              </h4>
              {proposal?.description && (
                <ReactMarkdown
                  className={classes.markdown}
                  children={processProposalDescriptionText(proposal.description, proposal.title)}
                  remarkPlugins={[remarkBreaks]}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col className={classes.section}>
              <h5>
                <Trans>Discussions</Trans>
              </h5>
              <a href={'https://discourse.nouns.wtf/t/small-grants-nouns-news/1220'}>
                https://discourse.nouns.wtf/t/small-grants-nouns-news/1220
              </a>
            </Col>
          </Row>
          <Row>
            <Col className={classes.section}>
              <h5>
                <Trans>Proposed Transactions</Trans>
              </h5>
              <ol>
                {proposal?.details?.map((d, i) => {
                  return (
                    <li key={i} className="m-0">
                      {linkIfAddress(d.target)}.{d.functionSig}
                      {d.value}(
                      {d.callData.split(',').map((content, i) => {
                        return (
                          <Fragment key={i}>
                            <span key={i}>
                              {linkIfAddress(content)}
                              {d.callData.split(',').length - 1 === i ? '' : ','}
                            </span>
                          </Fragment>
                        );
                      })}
                      )
                    </li>
                  );
                })}
              </ol>
            </Col>
          </Row>
        </div>
        <div className={classes.information}>
          <div className={classes.infoCard}>
            <div className={classes.cardHeader}>Information</div>
            <div className={classes.cardBody}>
              {listInfo.map(info => (
                <div className={classes.cardBodyWrapper}>
                  <div className={classes.cardBodyLeft}>{info.left}</div>
                  <div className={classes.cardBodyRight}>
                    {info.right?.img && <img src={info.right?.img} alt="logo" />}
                    <div>{info.right?.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.infoCard}>
            <div className={classes.cardHeader}>Cast your vote</div>
            {true ? (
              <div className={classes.cardCastVote}>
                <div>For</div>
                <div>Against</div>
                <div>Abstain</div>
                <button>Submit Vote</button> 
              </div>
            ) : (
              <div className={classes.cardNoData}>No data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProposalContent;
