import { Proposal } from '../../wrappers/nounsDao';
import { Alert, Button, Dropdown, Form } from 'react-bootstrap';
import ProposalStatus from '../ProposalStatus';
import classes from './Proposals.module.css';
import { useHistory } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
import { isMobileScreen } from '../../utils/isMobile';
import clsx from 'clsx';
import { useUserVotes } from '../../wrappers/nounToken';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import watch from '../../assets/icons/watch.svg';
import empty from '../../assets/empty.png';
import { useState } from 'react';
import {DatePicker,Divider,Switch  } from "antd";
import Otherpage from '../OtherPage';
const { RangePicker } = DatePicker;
const Proposals = ({ proposals }: { proposals: Proposal[] }) => {
  const listStatus = ['All status', 'pending', 'active', 'executed', 'defeated', 'canceled', 'expired'];
  const history = useHistory();
  const { account } = useEthers();
  const connectedAccountNounVotes = useUserVotes() || 0;
  const [startDate, setStartDate] = useState(new Date("2014/02/08"));
  const [endDate, setEndDate] = useState(new Date("2014/02/10"));
  const isMobile = isMobileScreen();
  const [status,setStatus] = useState<string>('All status')

  const nullStateCopy = () => {
    if (account !== null) {
      return <Trans>You have no Votes</Trans>;
    }
    return <Trans>Connect wallet to make a proposal</Trans>;
  };

  return (
    <div className={classes.proposals}>
      <div>
        <h3 className={classes.heading}>
          <Trans>Proposals</Trans>
        </h3>
        {account !== undefined && connectedAccountNounVotes > 0 ? (
          <div className={classes.submitProposalButtonWrapper}>
            <Button className={classes.generateBtn} onClick={() => history.push('create-proposal')}>
              <Trans>Set Up Proposal</Trans>
            </Button>
          </div>
        ) : (
          <div className={clsx('d-flex', classes.submitProposalButtonWrapper)}>
            {!isMobile && <div className={classes.nullStateCopy}>{nullStateCopy()}</div>}
            <div className={classes.nullBtnWrapper}>
              <Button className={classes.generateBtnDisabled}>
                <Trans>Set Up Proposal</Trans>
              </Button>
            </div>
          </div>
        )}
      </div>
      {isMobile && <div className={classes.nullStateCopy}>{nullStateCopy()}</div>}
      <form className={classes.filterProposals}>
        <div className={classes.filterWrapper}>
        <Dropdown
          // onToggle={() => setButtonUp(!buttonUp)}
          autoClose={true}
          className={classes.dropdownBox}
        >
          <Dropdown.Toggle className={classes.selectStatus}>{status}</Dropdown.Toggle>
          <Dropdown.Menu className={classes.selectStatusBox}>
            {listStatus.map(status => (
              <Dropdown.Item href="#" className={classes.selectStatusBoxItem} onClick={()=>setStatus(status)}>
                {status}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown> 
        <Divider type="vertical" className={classes.divider}/>
        <RangePicker className={classes.filterDate} inputReadOnly/>
        <Divider type="vertical" className={classes.divider}/>
        <div style={{marginRight:'8px'}}>My Proposals</div>
        <Switch defaultChecked/>
        </div>
        <div className={classes.clearAll}>Clear All</div>
      </form>
      <div className={classes.ProposalsWrapperComing}>
        <Otherpage type="coming soon" showBtn={false}/>
      </div>
      {/* <div className={classes.ProposalsWrapper}>
        {proposals?.length ? (
          proposals
            .slice(0)
            .reverse()
            .map((p, i) => {
              return (
                <div
                  className={classes.proposalLink}
                  onClick={() => history.push(`/vote/${p.id}`)}
                  key={i}
                >
                  <div className={classes.proposalStatusWrapper}>
                    <ProposalStatus status={p.status}></ProposalStatus>
                  </div>
                  <span className={classes.proposalId}>
                    Proposals {i18n.number(parseInt(p.id || '0'))}
                  </span>{' '}
                  <span className={classes.proposalTitle}>
                    <span>{p.title}</span>
                  </span>
                  <div className={classes.dateTime}>
                    <img src={watch} alt="icon-time" />
                    Created on May 11, 2022
                  </div>
                </div>
              );
            })
        ) : (
          <div className={classes.NotFound}>
            <img src={empty} alt="not found" />
            <p>
              <Trans>No proposals found.</Trans>
            </p>
            <span>Proposals submitted by community members will appear here.</span>
          </div>
        )}
      </div> */}
    </div>
  );
};
export default Proposals;
