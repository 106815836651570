import { Col, Row } from 'react-bootstrap';
import Section from '../../layout/Section';
import { useAllProposals, useProposalThreshold } from '../../wrappers/nounsDao';
import Proposals from '../../components/Proposals';
import classes from './Governance.module.css';
import { utils } from 'ethers/lib/ethers';
import clsx from 'clsx';
import { useTreasuryBalance, useTreasuryUSDValue } from '../../hooks/useTreasuryBalance';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import ModalCastVote from '../../components/ModalCastVote';
import Otherpage from '../../components/OtherPage';

const GovernancePage = () => {
  const { data: proposals } = useAllProposals();
  const threshold = useProposalThreshold();
  const nounsRequired = threshold !== undefined ? threshold + 1 : '...';

  const treasuryBalance = useTreasuryBalance();
  const treasuryBalanceUSD = useTreasuryUSDValue();

  // Note: We have to extract this copy out of the <span> otherwise the Lingui macro gets confused
  const nounSingular = <Trans>Noun</Trans>;
  const nounPlural = <Trans>Nouns</Trans>;

  return (
    <>
      <Section fullWidth={false} className={classes.section}>
        <Col lg={12} className={classes.wrapper}>
          <Row className={classes.headerRow}>
            {/* <span>
            <Trans>Governance</Trans>
          </span> */}
            <h1>
              <Trans>Emporia DAO</Trans>
            </h1>
          </Row>
          <p className={classes.subheading}>
            <Trans>
              RENA tokens represent voting shares in Emporia governance. You can vote on proposals
              or delegate their vote to a third party. A minimum of 10 RENA is required to submit
              proposals.
            </Trans>
          </p>

          <Row className={classes.treasuryInfoCard}>
            <Col lg={12} className={classes.treasuryAmtWrapper}>
              <Row className={classes.headerRow}>
                <span>
                  <Trans>Treasury</Trans>
                </span>
              </Row>
              <Row>
                <Col className={clsx(classes.ethTreasuryAmt)} lg={3}>
                  <h1>
                    {treasuryBalance &&
                      i18n.number(
                        Number(Number(utils.formatEther(treasuryBalance)).toFixed(0)),
                      )}{' '}
                    RENA
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col className={classes.usdTreasuryAmt}>
                  <h1 className={classes.usdBalance}>
                    $
                    {treasuryBalanceUSD &&
                      i18n.number(Number(treasuryBalanceUSD.toFixed(0)), {
                        style: 'currency',
                        currency: 'USD',
                      })}
                  </h1>
                </Col>
              </Row>
            </Col>
          </Row>
          <Proposals proposals={proposals} />
        </Col>
      </Section>
    </>
  );
};
export default GovernancePage;
