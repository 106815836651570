import { Col, Image } from 'react-bootstrap';
import Section from '../../layout/Section';
import classes from './NotFound.module.css';
import _404img from '../../assets/404noun.png';
import { Trans } from '@lingui/macro';
import Otherpage from '../../components/OtherPage';

const NotFoundPage = () => {
 
  return (
    <Section fullWidth>
      <div className={classes.otherPageWrapper}>
      <Otherpage type="404"/>
      </div>
    </Section>
  );
};
export default NotFoundPage;
