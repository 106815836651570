import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import classes from './VoteHistoryModal.module.css';
import sortIcon from '../../assets/icons/sort.svg'
import Avatar from '../../assets/avatar.png'
interface VoteHistoryModalProps {
  show: boolean;
  onHide: () => void;
}

function VoteHistoryModal({ show, onHide }: VoteHistoryModalProps) {
  return (
    <>
      <Modal contentClassName={classes.VoteHistoryModal} show={show} onHide={onHide} centered>
        <Modal.Header closeButton closeVariant="white" className={classes.VoteHistoryHeader}>
          <div className={classes.VoteHistoryHeaderWrapper}>
            <div>voter name</div>
            <div style={{marginLeft:'4rem'}}>option</div>
            <div>total votes cast <img src={sortIcon}/></div>
          </div>
        </Modal.Header>
        <Modal.Body className={classes.VoteHistoryBody}>
          <div className={classes.rowBody}>
            <div className={classes.rowBodyVoter}>
                <img src={Avatar} alt="voter"/> 
                <div className={classes.rowBodyVoterName}>
                    <span>shagou</span>
                    <span>0xd7c35******7F9ae0</span>
                </div>
            </div>
            <div style={{marginLeft:'4rem'}}>For</div>
            <div>total votes cast</div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VoteHistoryModal;
