import React from 'react';
import classes from './otherpage.module.css';
import img404 from '../../assets/404.png';
import comingSoon from '../../assets/coming_soon.png';
import noInternet from '../../assets/no_internet.png';
import img505 from '../../assets/505.png';
interface OtherPageProps {
    type: '404' | 'coming soon' | 'no intenet' | 'server error' | 'maintenance'
    showBtn?: boolean;
}
function Otherpage({type = 'coming soon',showBtn = true}: OtherPageProps) {
    const listType:any = {
        '404':{
            image:img404,
            title:'Page not found',
            subTitle:'This page doesn’t exist or was removed! We suggest you back to home.',
            btnTitle:'Go to Home',
            link:'/'
        },
        'coming soon':{
            image:comingSoon,
            title:'Coming Soon',
            subTitle:'We’re going to launch our Website very soon. Stay tune.',
            btnTitle:'Go to Home',
            link:'/' 
        },
        'no intenet':{
            image:noInternet,
            title:'No Internet',
            subTitle:'Couldn’t connect to internet. Please check your network settings.',
            btnTitle:'Retry',
            link:'/' 
        },
        'server error':{
            image:img505,
            title:'Internal Server Error',
            subTitle:'The server has been deserted for a while.Try to refesh this page or feel free to contact us if the problem persists.',
            btnTitle:'Retry',
            link:'/' 
        },
        'maintenance':{
            image:img505,
            title:'Maintenance System',
            subTitle:'Sorry, we are down for maintenance but will be back in no time.',
            btnTitle:'Go to Home',
            link:'/' 
        }
    }
    const itemSelect = listType[type]
  return (
    <div className={classes.otherWrapper}>
      <img className={classes.otherImage} src={itemSelect.image} alt={type} />
      <div className={classes.otherTitle}>{itemSelect.title}</div>
      <div className={classes.otherSubtitle}>
      {itemSelect.subTitle}
      </div>
      {showBtn&&<a className={classes.otherLink} href={itemSelect.link}>{itemSelect.btnTitle}</a>}
    </div>
  );
}

export default Otherpage;
